import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled, currentUser } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer homepage={true} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>

          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionLocations currentUser={currentUser} history={history} />
              </div>
            </li>

            {/*<li className={css.section}>
              <div className={css.howItWorksSection}>
                <div className={css.howItWorksBox}>
                  <h3 className={css.howItWorksTitle}> How Lenddit Works </h3>
                </div>
                <div className={css.howItWorksBoth}>
                  <div className={css.howItWorksOwners}>
                    <div className={css.howItWorksHeader}> For Owners</div>
                    <div className={css.howItWorksSubSection}>
                      <div className={css.howItWorksStep}>List <span className={css.howItWorksEmoji}>📝</span></div>
                      <div className={css.howItWorksStep}>Exchange <span className={css.howItWorksEmoji}>🔄</span></div>
                      <div className={css.howItWorksStep}>Monitor <span className={css.howItWorksEmoji}>🖥️</span></div>
                      <div className={css.howItWorksStep}>Earn <span className={css.howItWorksEmoji}>💰</span></div>
                    </div>
                  </div>
                  <div className={css.howItWorksRenters}>
                    <h2 className={css.howItWorksHeader}>For Renters</h2>
                    <div className={css.howItWorksSubSection}>
                      <div className={css.howItWorksStep}>Browse <span className={css.howItWorksEmoji}>🔍</span></div>
                      <div className={css.howItWorksStep}>Exchange <span className={css.howItWorksEmoji}>🔄</span></div>
                      <div className={css.howItWorksStep}>Rent <span className={css.howItWorksEmoji}>🛠️</span></div>
                      <div className={css.howItWorksStep}>Complete Project <span className={css.howItWorksEmoji}>✅</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </li> */}
            {/* New Sweepstakes Announcement Div */}

            {/*<div className={css.sweepstakesBox}>
              <h2>Congratulations to our Sweepstakes Winner!</h2>
              <a href="https://www.lenddit.com/giveaway-winners" className={css.sweepstakesLink}>
                <p>Click here to see who won and learn more.</p>
              </a>
          </div>*/}


            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks history={history} />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
